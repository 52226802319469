.header__wrap {
  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    grid-area: header;

    .header__logo {
      position: absolute;
      cursor: pointer;
      transition: opacity 0.5s;
      margin-left: 5%;
      align-self: center;
    }

    .header__search {
      display: flex;
      align-self: center;

      @media screen and (max-width: 1020px) {
        display: none;
      }

      padding: 0 84px;

      @media screen and (max-width: 1140px) {
        padding: 0 44px;
      }

      @media screen and (max-width: 1070px) {
        padding: 0 44px 0px 0px;
      }
    }

    &.menu-open {
      height: 100vh;
      width: 100vw;
      z-index: 3;
      background: white;
      position: fixed;
      box-sizing: border-box;
      overflow-y: auto;

      &:after {
        content: "";
        background: url("../../images/LINES_BG.png");
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }

      .header__logo {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
