@import '~styles/variables';

@keyframes slide-in-review-form {
    from {left: -200%;}
    to {left: 0%;}
}

@keyframes submit-review-form {
    from {left: 0%;}
    to {left: 200%;}
}

@keyframes cancel-review-form {
    from {left: 0%;}
    to {left: -200%;}
}

.trail-review-form {
    position: fixed;
    left: -200%;

    &_active {
        position: fixed;
        animation-name: slide-in-review-form;
        animation-duration: .5s;
        margin-top: 2%;
        margin-bottom: 2%;
        padding: 0 5%;
        background-color: $ad-background;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: calc(100vh - 50px);
        max-width: calc(100vw - 50px);
    }

    &_submitted {
        position: fixed;
        animation-name: submit-review-form;
        animation-duration: .5s;
        left: -200%;
        margin: 5% 20%;
        background-color: $ad-background;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 50px);
        max-width: calc(100vw - 50px);
    }

    &_cancelled {
        position: fixed;
        animation-name: cancel-review-form;
        animation-duration: .5s;
        left: -200%;
        margin: 5% 20%;
        background-color: $ad-background;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 50px);
        max-width: calc(100vw - 50px);
    }
}

.trail-review-overlay {
    top: 0%;
    left: 0%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-color: $dark-gray-opacity-20;
}

.review-hero-image {
    background-size: cover;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 0px 12px 6px 0px;
    height: 135px;
    min-height: 135px;
    width: 250px;
  }

.trail-review-form-title {
    font-family: Prohibition, sans-serif;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    justify-content: space-between;
    color: $blue;
}

.trail-review-sub-title {
    font-family: Prohibition, sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: $lime;
    margin-bottom: 5px;

    &_subtext {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $subtitle-gray;
    }
}

.trail-review-optional-title {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: $lime;
}

.trail-review-form-submit-button {
  display: flex;
  background-color: $lime;
  color: white;
  font-size: 16px;
  line-height: 18px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-transform: none;
  padding: 6px 16px;
  margin: 0% 5%;
  width: fit-content;
  align-self: center;
}

.trail-review-empty-badge {
    border-radius: 50%;
    border-style: dotted;
    border-width: 2px;
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
    margin: 15px 15px 15px 0px;
    cursor: pointer;
}

.trail-review-top-icon {
    color: $orange;
    background-color: $orange-opacity-05;
    border-radius: 50%;
    padding: 8px;
    height: 19px;
    width: 19px;
    min-height: 19px;
    min-width: 19px;
    margin: 12px 8px 12px 0px;
    cursor: pointer;
}

.trail-review-form-badge-title {
    display: flex;
    flex-wrap: nowrap;
    color: $blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    line-height: 18px;
}

.trail-review-form-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.trail-review-form-badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.trail-review-form-headline {
    min-width: 66%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
}

.trail-review-form-body {
    width: 100%;
    min-height: 128px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    resize: none;
    font-size: 14px;
}