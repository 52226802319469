@import '~styles/variables';

$table-data-border: $light-gray;
$blue-opacity-50: #254c6585;

@mixin table-cell {
  padding: 20px 0 20px 30px;
  font-size: 14px;
  letter-spacing: 0.59px;
  line-height: 17px;
  text-align: left;
  background: $white;
}

.pageHeader {
  display: flex;
  flex-direction: row;

  &__Button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.top-icons-search {
  display: flex;
  justify-content: flex-start;

  .top-icon-search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    white-space: nowrap;
    position: relative;
    color: $orange;

    span {
      display: flex;
      background-color: $orange-opacity-05;
      border-radius: 50%;
      padding: 8px;
    }

    .hover-text {
      visibility: hidden;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      width: auto;
      background-color: $dark-gray;
      color: $white;
      text-align: center;
      padding: 5px 5px;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      top: 36px;
    }

    &:hover .hover-text {
      visibility: visible;
    }
  }
}

.trailsTable {
  &__table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 24px;
  }

  &__th {
    @include table-cell;
    background-color: $blue;
    color: $white;

    &:first-of-type {
      border-radius: 4px 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 4px 0 0;
    }
  }

  &__th-recommend {
    @include table-cell;
    background-color: $blue;
    color: $orange;
  }

  tr:hover td {
    background: rgba(#ccc, .5);
  }

  &__td {
    @include table-cell();
    color: $blue;
    border-bottom: 1px solid $table-data-border;

    &:last-of-type {
      padding-right: 30px;
    }
  }

  &__td__trail-name {
    @include table-cell();
    color: $blue;
    border-bottom: 1px solid $table-data-border;

    &:last-of-type {
      padding-right: 30px;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__td-lightBlue {
    @include table-cell();
    color: $blue-opacity-50;
    border-bottom: 1px solid $table-data-border;
  }

  &__td-lightBlueCenter {
    @include table-cell();
    color: $blue-opacity-50;
    border-bottom: 1px solid $table-data-border;
  }

  &__td-orangeCenter {
    @include table-cell();
    color: $orange;
    border-bottom: 1px solid $table-data-border;
  }

  &__filter-section {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
  }

  &__filter-section-title {
    margin-bottom: 6px;
    font-size: 16px;
  }

  &__filter {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-style: normal;
  }

  &__filters {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-style: bold;
    color: $blue;
  }
}

.clickable-visible-tags {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
