@import "~styles/variables";

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size !important;
      height: $size !important;
      -webkit-overflow-scrolling: auto;
      -webkit-appearance: none;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color !important;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color !important;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color !important;
      scrollbar-track-color: $background-color !important;
    }
}

.trail_related_blog_arrows_container {
    display: flex;
    position: relative;

    @media screen and (max-width: 520px) {
        display: none;
    }
  }

.trail_related_blog_container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h2 {
        color: $lime;
        margin: 20px 0 0 10px;
    }
}

.trail_related_blog_blogs {
    display: flex;
    width: 100%;
    max-width: 1100px;
    overflow-x: scroll;
    @include scrollbars(1px, $blue, $light-gray);
}

.trail_related_blog_blogs_mobile {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
}

.trail_related_blog_carousel {
    background-color: transparent;
    width: 100%;
    display: none;

    .dot.selected {
        background-color: $blue !important;
    }

    .dot {
        border: 1px solid $review-text-gray !important;
    }

    .control-dots {
        position: relative !important;
    }

    li {
        background-color: transparent !important;
    }

    @media screen and (max-width: 520px) {
        display: flex;
    }
}

.trail_related_blog_left_arrow {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    left: 0;
    top: 40%;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $subtitle-gray;
    opacity: 0.9;
  }
  
  .trail_related_blog_right_arrow {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    left: 93%;
    top: 40%;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $subtitle-gray;
    opacity: 0.9;
  
    @media screen and (max-width: 1170px) {
      left: 90%;
    }
  
    @media screen and (max-width: 969px) {
      left: 93%;
    }
  
    @media screen and (max-width: 600px) {
      left: 90%;
    }
  
    @media screen and (max-width: 440px) {
      left: 87%;
    }
  
    @media screen and (max-width: 400px) {
      left: 84%;
    }
  }
