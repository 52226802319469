.trailCard__background {
    background-size: cover;
    border-radius: 2%;
}

.trailCard__body {
    padding: 10px;
    border-radius: 2%;
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color:rgba(0, 0, 0, 0.3);

    .trailCard__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    h2, h3 {
        margin: 8px 0px;
        margin-right:5px;
    }

    h3 {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
    }
}

.trailCard__details {
    display: flex;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    font-weight: 400;

    .trailCard__cities {
    width: 120px;
    }

    .trailCard__Divider {
     border-left: 2px solid white;
     height: auto;  
    }

    .trailCard__detailsColumn {
        flex-direction: column;

        .trailCard__distance {
            margin: 0px 0px 5px 30px;
        }

        .trailCard__features {
            flex-direction: row;
            
            span {
                margin: 15px 0px 15px 30px;
                font-size: 18px;
            }
        }
    }
}

.trailCard__tags {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
   
    span {
        margin-right: 8px;
    }
}