@import "~styles/variables";

.trail_related_video_container {

  h2 {
      color: $lime;
      padding: 0% 5%;
  }
}

.trail_related_video_carousel {
  max-width: 635px;
  margin: auto;
  z-index: 0;
  position: relative;

  .arrow{
    z-index: 1;
    position: absolute;
    top: calc(50% - 40px);
  }

  .dot.selected {
      background-color: $blue !important;
  }

  .dot {
      border: 1px solid $review-text-gray !important;
  }

  .control-dots {
      position: absolute !important;
  }

  @media screen and (max-width: 520px) {
      //display: flex;
  }
}


