.carousel__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.slide .carousel__map-image {
    height: 493px;

    .carousel__image {
        object-fit: cover;
        width: 100%; 
        height: auto;
        max-height:493px;
        @media screen and (max-width: 1108px) {
            height: 100%;
        }
    }
}

.carousel__hero-frame {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}