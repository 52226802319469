@import '~styles/variables';

.point_of_interest_preview_background_image {
    height: 190px;
    width: 315px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    background-size: cover;
}

.point_of_interest_preview_top {
    height: 170px;
    width: 295px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    background-size: cover;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: white;
    background-color:rgba(0, 0, 0, 0.2);
}

.point_of_interest_preview_title {
    align-self: flex-end;
    font-size: 16px;
    font-family: Prohibition, sans-serif;
}

.point_of_interest_preview_bottom {
    display: flex;
    flex-direction: column;
    height: 150px;
    width: 275px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    background-color: white;
    padding: 20px;
    overflow-y: auto;
}

.point_of_interest_preview {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
}

.point_of_interest_preview_street_address {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: $blue;
}

.point_of_interest_preview_distance_from_trail {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: $blue;
}

.point_of_interest_preview_description {
    padding: 2% 0%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: normal;
    color: gray;
    font-weight: lighter;
    white-space:normal;
    font-size: 14px;
}

.point_of_interest_preview_learn_more_button {
    align-self: flex-start;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid $point-of-interest-blue;
    color: $point-of-interest-blue;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
    padding: 4px 12px;
}

.poi-exit-popup {
    background-color: $light-gray;
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
}