.authWrapper {
  display: grid;
  grid-template-areas: ". content .";
  grid-template-columns: 1fr 100% 1fr;
  grid-template-rows: auto;

  &__content {
    grid-area: content;
  }
}
