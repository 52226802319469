@import "~styles/variables";

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size !important;
    height: $size !important;
    -webkit-overflow-scrolling: auto;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color !important;
  }

  &::-webkit-scrollbar-track {
    background: $background-color !important;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color !important;
    scrollbar-track-color: $background-color !important;
  }
}

.trail_points_of_interest_wrapper {
  display: flex;
  flex-direction: column;
}

.trail_points_of_interest_header {
  display: flex;
  justify-content: flex-start;
  padding: 0px 15px;
  align-items: center;

  .trail_points_of_interest_header_title {
    color: $lime;
  }

  .trail_points_of_interest_header_show_on_map {
    display: flex;
    align-items: center;
    height: 22px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: #061822;
  }
}

.trail_points_of_interest_container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
  padding-bottom: 20px;

  div.point_of_interest_card:last-of-type {
    border-right: 10px solid $page-background-gray;
  }

  @include scrollbars(1px, $blue, $light-gray);
}

.trail_points_of_interest_arrows_container {
  display: flex;
  position: relative;
}

.trail_points_of_interest_container_left_arrow {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  left: 0;
  top: 40%;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $subtitle-gray;
  opacity: 0.9;
}

.trail_points_of_interest_container_right_arrow {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  left: 93%;
  top: 40%;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: $subtitle-gray;
  opacity: 0.9;

  @media screen and (max-width: 1170px) {
    left: 90%;
  }

  @media screen and (max-width: 969px) {
    left: 93%;
  }

  @media screen and (max-width: 600px) {
    left: 90%;
  }

  @media screen and (max-width: 440px) {
    left: 87%;
  }

  @media screen and (max-width: 400px) {
    left: 84%;
  }
}
