@import '~styles/variables';

.ad-bar__card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 400px;
    margin: 8px;

    @media screen and (max-width: 416px) {
      max-width: 300px;
  }
}

.ad-bar__section {
  width: auto;
  background-color:  '#FFFFFF';
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  margin-bottom: 25px;
}

.ad-bar__section-blue {
  width: auto;
  background: $blue url("../../images/LINES_BG.png");
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}