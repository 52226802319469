@import "~styles/variables";

.recent_blog_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h4 {
        font-family: Prohibition, sans-serif;
        color: $blue;
        font-size: 40px;
        margin: 20px;
        text-align: center;
    }

    h6 {
        font-family: Prohibition, sans-serif;
        color: $blue;
        font-size: 24px;
        margin: 20px;
        font-weight: 100;

        &:hover {
            text-decoration: underline;
        }
    }
}

.recent_blog_blogs {
    display: flex;
    width: 100%;
    max-width: 1100px;
    justify-content: space-around;
    overflow-x: scroll;

    @media screen and (max-width: 1040px) {
        justify-content: space-between;
    }

    @media screen and (max-width: 520px) {
        display: none;
    }
}

.recent_blog_blogs_mobile {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
}

.recent_blog_carousel {
    background-color: transparent;
    width: 100%;
    display: none;

    .dot.selected {
        background-color: $blue !important;
    }

    .dot {
        border: 1px solid $review-text-gray !important;
    }

    .control-dots {
        position: relative !important;
    }

    li {
        background-color: transparent !important;
    }

    @media screen and (max-width: 520px) {
        display: flex;
    }
}
