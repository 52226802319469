@import '~styles/variables';

@mixin page-select-font {
    font-size: 16px;
    letter-spacing: 0.59px;
    text-align: left;
    color: $blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-color: #C4C4C4;
}

.pageBar__pageSelectBar {
    display: flex;
    justify-content: flex-end;
    @include page-select-font();

    span {
        align-self: center;
    }

    .pageBar__pageButtonTable {
        border-collapse: collapse;
    }
    .PageBar__pageButtonGroup {
        display: flex;
        margin: 0px 10px;
        
        button {
            @include page-select-font();
            background-image: linear-gradient(to bottom, rgb(254, 254, 254), rgb(240, 241, 243));
            padding: 1px 15px 2px;
            &:first-of-type {
                border-radius: 4px 0 0 4px;
            }
            &:last-of-type {
                border-radius: 0 4px 4px 0;
            }
            &:hover {
                cursor: pointer;
            }
        }

        .PageBar__current_button {
            outline:0;
            background-image: linear-gradient(to bottom, rgb(199, 199, 199), rgb(236, 237, 239));
        }
    }

    .pageBar__previousButton {
        @include page-select-font();
        border-width: 1.5px;
        border-style: solid;
        border-radius: 4px;
        margin: 0px 10px 0px 0px;
        background-image: linear-gradient(to bottom, rgb(254, 254, 254), rgb(240, 241, 243));
        text-transform: lowercase;
    }

    .pageBar__first_button {
        @include page-select-font();
        border-width: 1.5px;
        border-style: solid;
        border-radius: 4px;
        background-image: linear-gradient(to bottom, rgb(254, 254, 254), rgb(240, 241, 243));
        text-transform: lowercase;
    }
}

