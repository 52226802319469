@import '~styles/variables';

.min-preview-trail-detail {
  padding: 18px;
  background: $ad-background;
  border-radius: 25px;
  opacity:0.9;
}

.min-preview-trail-detail {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 968px) {
    display: block;
  }

  .text {
    max-width: 200px;
  }

  .min-trail-title {
      color: $blue;
      font-size: 20px;
      font-weight: 500;
      margin: 0;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      letter-spacing: normal;
      width: 100%;
  }

  .min-preview-text-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .min-preview-icons {
    display: flex;
    width: 100%;
    color: $dark-gray;
    flex-wrap: wrap;
    justify-content: space-around;

    .min-preview-icon {
      display: flex;
      align-items: center;
      margin: 5px;
      white-space: nowrap;
      position: relative;

      span:first-child {
        margin-right: 8px;
      }

      span:nth-child(2n+1) {
        display: flex;
        background-color: $light-gray;
        border-radius: 50%;
        padding: 8px;
      }

      .hover-text {
        visibility: hidden;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        width: auto;
        background-color: $dark-gray;
        color: $white;
        text-align: center;
        padding: 5px 5px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        top: 36px;
      }

      &:hover .hover-text {
        visibility: visible;
      }

      span:nth-child(2n+2) {
        display: flex;
        justify-content: center;

      }
    }
  }

  .view-button {
    color: $white;
    background-color: $lime;
    padding: 5px 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .min-exit-popup {
    background-color: transparent;
  }

}
