.profile-fb-icon {
  color: #3c5a99;
  height: 100%;
  width: 24px;
}

.profile-google-icon {
  height: 100%;
  width: 24px;
}

.profile-connected-account {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile_connect_button_text {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 13px;
  line-height: 15px;
  text-transform: none;
}