@import '~styles/variables';

.top-icons {
    display: flex;
    width: auto;
    justify-content: flex-end;

  .top-icon-text {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    color: $orange;
    font-size: 12px;
  }

  .top-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    white-space: nowrap;
    position: relative;
    color: $orange;

    span {
      display: flex;
      background-color: $orange-opacity-05;
      border-radius: 50%;
      padding: 8px;
    }

    .hover-text {
      visibility: hidden;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      width: auto;
      background-color: $dark-gray;
      color: $white;
      text-align: center;
      padding: 5px 5px;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      top: 36px;
      right: 36px;
    }

    &:hover .hover-text {
      visibility: visible;
    }
  }
}
