@import "~styles/variables";

.home__hero {
  /* background-size: 100% 100%; */
  color: #fff;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.home__hero-text {
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home__hero_slogan {
  font-size: 72px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 6px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 50px;
  }
}

.home__hero_slogan_subtitle {
  font-size: 20px;
  font-weight: 100;
  margin: 0 10px 48px 10px;
  text-align: center;
}

.home__hero-options {
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.home__search-bar {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.home__tag {
  border-color: #fff;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;

  .home__tag-text {
    color: #fff;
  }
}

.tag {
  display: flex;
  margin: 0 1vw;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-color: $white;
  padding: 0 24px;
  color: $white;
  white-space: nowrap;
  height: 46px;
  letter-spacing: 0.5px;

  @media screen and (max-width: 420px) {
    padding: 0 24px;
    font-size: 13px;
  }

  .tag-text {
    color: $white;
    white-space: nowrap;
    display: flex;
    align-items: center;

    @media screen and (max-width: 420px) {
      font-size: 13px;
    }
  }
  .tag-icon {
    margin: 2% 0 0 8%;
    color: $white;
    height: 100%;
  }
}

.home__states {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
  position: absolute;
  top: 42px;
  text-align: left;
  background: rgba(#fff, 0.9);
  margin-left: 1vw;
  margin-right: 1vw;
  padding: 0;
  border-radius: 6px;

  &.home__states_active {
    max-height: 125px;
    padding: 4px 0 12px;
    z-index: 2;
  }

  li {
    list-style: none;
    padding: 8px 36px;
    color: #000;

    &:hover {
      color: $blue;
    }
  }
}

.home__welcome-body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  letter-spacing: 0.5px;
  max-width: 800px;
  padding: 32px;
  margin: 0 auto;
}

.home__state_or_search {
  display: flex;
  justify-content: space-around;
  width: 50%;
  align-items: center;
  font-size: 17px;

  @media screen and (max-width: 890px) {
    flex-direction: column;
  }
}
