@import "~styles/variables";

.nav-link-item {
    display: flex;
    padding-right: 16px;
    padding-left: 16px;
    align-self: center;
    align-items: center;
    color: $dark-gray;
    text-decoration: none;
}

.nav-link-item-active {
    padding-right: 16px;
    padding-left: 16px;
    align-self: center;
    color: $lime;
    text-decoration: none;
    box-sizing: border-box;
    height: 100%;
}