@import '~styles/variables';

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width:  $size !important;
        height: $size !important;
        -webkit-overflow-scrolling: auto;
        -webkit-appearance: none;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $foreground-color !important;
      }
    
      &::-webkit-scrollbar-track {
        background: $background-color !important;
      }
    
      // For Internet Explorer
      & {
        scrollbar-face-color: $foreground-color !important;
        scrollbar-track-color: $background-color !important;
      }
}

.point_of_interest_card_background_image {
    height: 165px;
    width: 315px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    background-size: cover;
}

.point_of_interest_card_top {
    height: 125px;
    width: 275px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
    color: white;
    background-color:rgba(0, 0, 0, 0.2);

    .point_of_interest_card_name {
        font-size: 20px;
        width: 100%;
        white-space: normal;
    }
}

.point_of_interest_card_bottom {
    display: flex;
    flex-direction: column;
    height: 150px;
    width: 295px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    background-color: white;
    padding: 10px 10px 0px 10px;
    overflow-y: auto;

    @include scrollbars(1px, $blue, $light-gray);
}

.point_of_interest_card {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 23px;
}

.point_of_interest_street_address {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: $blue;
}

.point_of_interest_distance_from_trail {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: $blue;
}

.point_of_interest_description {
    padding: 1.5% 0%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: gray;
    font-weight: lighter;
    white-space:normal;
    font-size: 14px;
}

.point_of_interest_learn_more_button {
    align-self: flex-start;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid $point-of-interest-blue;
    color: $point-of-interest-blue;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
    padding: 4px 12px;
}