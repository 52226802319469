@import '~styles/variables';

.blog-comment-post-button {
    display: flex;
    background-color: $lime;
    color: white;
    font-size: 20px;
    padding: 7px 30px;
    height: fit-content;
    width: fit-content;
    margin: 10px 0px;
}

.blog_comment_form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;

    @media screen and (max-width: 1040px) {
        width: 95vw;
        align-self: center;
    }

    textarea { 
        min-height: 150px;
        padding: 20px;
        border-radius: 3px;
        box-sizing: border-box;
        border-color: $subtitle-gray;
        outline: none;
        font-size: 15px;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
}