@import '~styles/variables';

.icons {
    display: flex;
    width: 95%;
    color: $dark-gray;
    flex-wrap: nowrap;

    .icon {
      display: flex;
      align-items: center;
      margin-right: 7px;
      white-space: nowrap;
      position: relative;
      @media screen and (max-width: 416px) {
        font-size: 1.1px;
      }
  
      @media screen and (min-width: 416px) and (max-width:500px) {
        font-size: 1.2px;
      }
  
      @media screen and (min-width: 500px) and (max-width:968px) {
        font-size: 2px;
      }
  
      @media screen and (min-width: 968px) {
        font-size: 1.4px;
      }

      .icon-title {

        @media screen and (max-width: 416px) {
          font-size: 10px;
        }

        @media screen and (min-width: 416px) and (max-width:500px) {
          font-size: 12px;
        }
    
        @media screen and (min-width: 500px) and (max-width:968px) {
          font-size: 16px;
        }
    
        @media screen and (min-width: 968px) {
          font-size: 12px;
        }
      }

      span:first-child {
        margin-right: 8px;

        @media screen and (max-width: 416px) {
        margin-right: 4px;
        font-size: 1.2px;
      }
      @media screen and (min-width: 416px) and (max-width:500px) {
          font-size: 1.8px;
        }
      }

      span:nth-child(2n+1) {
        display: flex;
        background-color: $light-gray;
        border-radius: 50%;
        padding: 8px;
       
        @media screen and (max-width:416px) {
          padding: 4px;
        }
        @media screen and (min-width: 416px) and (max-width:500px) {
          padding: 6px;
        }
      }

      .hover-text {
        visibility: hidden;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        width: auto;
        background-color: $dark-gray;
        color: $white;
        text-align: center;
        padding: 5px 5px;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        top: 36px;
        font-size: 14px;
      }

      &:hover .hover-text {
        visibility: visible;
      }

      span:nth-child(2n+2) {
        display: flex;
        justify-content: center;

      }
    }
  }