@import "~styles/variables";
@import "~styles/mixins";

.row-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .navigationBarItem {
    font-family: Prohibition, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.88px;
    color: rgba(6, 24, 34, 0.5);
  }
}

.column-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-links, .column-links {
  padding: 0 20px;
}

.sign-up-btn {
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.06px;
  text-align: center;
  color: #ffffff;
  background: $lime;
  padding: 0px 20px;
  height: 40px;
  align-self: center;
  min-width: 96px;

  .rounded-button {
    background: $lime;
  }
}


.three-column {
  @include grid-justify-between-columns(
          $columnCount: 3,
          $columnItemClass: "navigationBarItem"
  );
}

