@import '~styles/variables';

.trail-reviews-wrapper {
    display: flex;
    margin-right: 15%;

    @media screen and (max-width: 750px) {
        margin-right: 0;
    }
}

.trail-reviews-number-sort {
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $blue;
}

.review__sort_options {
    max-height: 0;
    overflow: hidden;
    transition: .5s;
    position: absolute;
    top: 20px;
    left: -10px;
    text-align: left;
    background: rgba(#fff, .9);
    margin-left: 1vw;
    margin-right: 1vw;
    padding: 0;
    border-radius: 6px;
    width: 130px;
  
    &.review__sort_options_active {
      max-height: 100px;
      padding: 4px 0 12px;
      z-index: 2;
      border: 1px solid $review-helpful-button-gray;
    }
  
    li {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 8px 18px;
        color: #000;
  
        &:hover {
            color: $blue;
        }
    }
  }

  .sort-reviews-button {
    background: #FFFFFF;
    position: relative;
    border: 1px solid #727D84;
    box-sizing: border-box;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;

    color: $blue;
    margin: 0px 10px 0px 10px;
    padding: 5px 5px 5px 5px;
  }
