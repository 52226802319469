@import '~styles/variables';

.mainHamburgerWrap {
  width: 30px;
  height: 43px;
  padding-top: 23px;
  padding-bottom: 23px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  -webkit-tap-highlight-color: transparent !important;
  cursor: pointer;
}

.ham-sign-up-btn {
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.06px;
  text-align: center;
  background: $lime;
  height: 40px;
  align-self: center;
  color: #ffffff;

  .rounded-button {
    background: $lime;
  }
}
