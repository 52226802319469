@import '~styles/variables';

.adv_search_state_filter_button {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: 2px solid $blue-opacity-65;
    font-size: 14px;
    margin: 20px 0px 10px 0px;
    color: $blue;
    padding: 10px 15px;
    position: relative;
    text-transform: none;

    @media screen and (max-width: 500px) {
        padding: 5px 10px;
        margin-left: 25px;
    }

    &__title {
        margin-right: 15px;
        font-weight: bold;

        @media screen and (max-width: 450px) {
            margin-right: 3px;
        }
    }

    &__selected {
        color: $blue-opacity-65;
        margin-right: 15px;
        font-size: 13px;

        @media screen and (max-width: 450px) {
            margin-right: 3px;
        }
    }
}

.adv_search__states {
    max-height: 0;
    overflow: hidden;
    transition: .5s;
    position: absolute;
    top: 30px;
    left: 0px;
    text-align: left;
    background: rgba(#fff, .9);
    margin-left: 1vw;
    margin-right: 1vw;
    padding: 0;
    border-radius: 6px;
  
    &.adv_search__states_active {
      max-height: fit-content;
      padding: 4px 0 12px;
      z-index: 2;
    }
  
    li {
      list-style: none;
      padding: 8px 36px;
      color: #000;
  
      &:hover {
        color: $blue;
      }
    }
}
