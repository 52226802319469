.footer {
  padding: 21px;
  background: rgba(37, 76, 101, 1) url("../../images/LINES_BG.png");
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 570px) {
    flex-direction: column;
  }

  .buttons-section {
    margin: 41px 0;
  }

  .logo-image {
    align-self: center;
    margin-bottom: 22px;
    text-align: center;
    width: 100%;
  }

  .copyright {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: normal;
    color: #677f91;
    font-weight: normal;
    text-align: center;
    display: flex;
    align-self: flex-end;

    @media screen and (max-width: 570px) {
      display: none;
    }
  }

  .contact {
    margin: 15px 0px 20px 0px;
    font-family: Prohibition, sans-serif;
    font-size: 16px;

    a {
    color: white;
    }

    @media screen and (max-width: 570px) {
      display: none;
    }
  }

  .copyright_social {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: normal;
    color: #677f91;
    font-weight: normal;
    text-align: center;
    display: flex;
    align-self: center;

    @media screen and (min-width: 570px) {
      display: none;
    }
  }

  .contact_social {
    margin: 15px 0px 20px 0px;
    font-family: Prohibition, sans-serif;
    font-size: 16px;
    align-self: center;

    a {
    color: white;
    }

    @media screen and (min-width: 570px) {
      display: none;
    }
  }
}