@import "~styles/variables";

.navigationBar {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 20px;

  @media screen and (max-width: 1021px) {
    justify-content: flex-end;
  }
}

.menu-desktop-links {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: 230px;

  @media screen and (max-width: 1020px) {
    display: none;
  }
}

.ham-menu {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: 1021px) {
    display: none;
  }
}
