@import '~styles/variables';

.user_profile_container {
  padding: 0px 20px;
}

.profile_header {
    background-color: transparent;
    color: $lime;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 46px;
  }

.add_border_lime {
    border: 1px solid $lime;
}

.profile_info_text {
    color: $blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-right: 10px;
}

.profile_pic_and_create_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.profile_image_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
}

.profile_about_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
      }

    hr {
        opacity: .3;
        width: 100%;
        height: 1px;
    }
}

.profile_info_container {
    width: 450px;
    margin-right: 20px;
}

.profile_content {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    margin: 0px 7%;
    margin-bottom: 20px;
}

.profile_connect_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #3F9ACD;
}

.profile_connect_icon {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.profile_connect_text {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: $blue;
    font-size: 13px;
    line-height: 15px;
    text-transform: none;
    padding: 1px 7px 2px;
}

.profile_connect_container {
    margin-top: 15px;
}