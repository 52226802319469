@import '~styles/variables';

.blogs_home_wrapper {
    background-color: $page-background-gray;
    display: flex;
    flex-direction: row-reverse;

    @media screen and (max-width: 730px) {
        margin: 0px;
        align-items: flex-start;
        flex-direction: column;
    }
}

.blogs_home_cards {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    width: fit-content;
    justify-content: center;

    @media screen and (max-width: 370px) {
        padding: 20px 0px;
    }
}

.blogs_home_header {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 300px;

    @media screen and (max-width: 730px) {
        align-self: center;
    }

}

.blog_home__states {
    max-height: 0;
    overflow: hidden;
    transition: .5s;
    position: absolute;
    top: 30px;
    left: 0px;
    text-align: left;
    background: rgba(#fff, .9);
    margin-left: 1vw;
    margin-right: 1vw;
    padding: 0;
    border-radius: 6px;
  
    &.blog_home__states_active {
      max-height: fit-content;
      padding: 4px 0 12px;
      z-index: 2;
    }
  
    li {
      list-style: none;
      padding: 8px 36px;
      color: #000;
  
      &:hover {
        color: $blue;
      }
    }
}

.blog_home_filter_button {
    border: 2px solid $blue;
    font-size: 21px;
    color: $blue;
    padding: 10px 15px;
    position: relative;

    &__title {
        margin-right: 3px;
    }

    &__selected {
        color: $blue-opacity-65;
        margin-right: 3px;
    }
}

.blogs_home_filter_column {
    padding-top: 40px;
    width: 400px;

    @media screen and (max-width: 450px) {
            display: none;
        }
}
