@import "~styles/variables";

.link-wrap {

  .link-anchor {
    cursor: pointer;
    font-size: 17px;
    text-decoration: none;
    display: flex;
    color: $dark-gray;
  }

  .in-window-link-anchor {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
}