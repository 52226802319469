@import '~styles/variables';

.point_of_interest_more_details {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    white-space: normal;
}

.point_of_interest_more_phone_icon {
    color: $point-of-interest-blue;
    font-size: 13px;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.point_of_interest_more_address {
    display: flex;
    color: $point-of-interest-blue;
    font-size: 13px;
    text-transform: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.point_of_interest_more_street_address {
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.point_of_interest_additional_info {
    color: $point-of-interest-blue;
    font-size: 13px;
    color: gray;
    text-transform: none;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.point_of_interest_more_website_button {
    margin-top: 5px;
    align-self: flex-start;
    background-color: $point-of-interest-blue;
    color: white;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
    font-weight: lighter;
    padding: 4px 12px;
}

.point_of_interest_phone_text {
    color: $point-of-interest-blue;
    text-decoration: none;
    font-size: 13px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
  }