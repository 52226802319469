@import '~styles/variables';

.elf-container {
  max-width: 90%;
  margin: auto;
  margin-bottom: 10px;
  opacity: .99; // this allows the trail review form to appear in front of this component
  overflow: hidden;
}

.elf-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  white-space: nowrap;

  &__hashtag {
    color: $blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 640;
  }

  &__instagram_logo {
    color: $lime;
  }

  &__hover-text {
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: auto;
    background-color: $light-gray;
    color: $dark-gray;
    text-align: center;
    padding: 5px 5px;
    margin-left: 7px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: -3px;

    &__visible {
      display: flex;
      flex-wrap: wrap;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      width: auto;
      background-color: $light-gray;
      color: $dark-gray;
      text-align: center;
      padding: 5px 5px;
      margin-left: 7px;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      left: 15px;
      top: -3px;
      visibility: visible;
    }
  }

  &__info {
    color: gray;

    &:hover ~ .elf-header__hover-text {
      visibility: visible;
    }
  }
}