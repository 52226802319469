@import '~styles/variables';

.relatedRoute {
   padding-bottom: 50px;
   
    h2 {
        color: $lime;
        margin: 20px 0 0 10px;
    }
    .relatedRoute__objectMap {
        display: flex;
        flex-wrap: wrap;
    }
}

.relatedRoute__object {
    display: flex;
    flex-direction: column;
    margin: 20px;
    overflow: wrap;
    
    &:hover {
        cursor: pointer;
        .relatedRoute__name {
        text-decoration: underline;
        }
    }

    .relatedRoute__photo {
        height: 100px;
        width: 160px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
       
        @media screen and (max-width: 968px) {
            height: 100px;
            width: 160px;
        }
    }

    .relatedRoute__info {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 14px;
        color: gray;
        margin-top: 7px;
        display: flex;
        flex-direction: column;
        max-width: 160px;
        
        @media screen and (max-width: 968px) {
            max-width: 160px;
        }

        .relatedRoute__name {
            font-size: 16px;
        }
    }
}