@import '~styles/variables';

.blog_more {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 250px;

    @media screen and (max-width: 810px) {
       width: 500px;
       max-width: none;
       margin-bottom: 30px;
    }

    @media screen and (max-width: 530px) {
       width: 250px;
    }

    h3{
        margin: 5px 0px;
        color: $blue;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.blog_more_background_image {
    background-size: cover;
    background-position: center;
    width: 250px;
    height: 100px;
    cursor: pointer;

    @media screen and (max-width: 810px) {
        width: 500px;
        height: 200px;
    }

    @media screen and (max-width: 530px) {
        width: 250px;
        height: 100px;
    }
}
