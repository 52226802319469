@import '~styles/variables';

.search__pageHeader {
  margin-left: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $lime;
}

.search_buttonDiv {
height: 24px;

  @media screen and (max-width: 825px) {
    height: 0px;
  }
}

.searchMobile__pageHeader {
  margin-left: 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  color: $lime;

  form {
    margin-left: 0px;
  }

  h1{
    margin: 0px;
  }
}

.title-message {
  margin-bottom: 5px;
}

.search_page_clickable_state {
  cursor: pointer;
  text-decoration: underline;
}

.search__number-of-trails {
  color: $blue;
  margin-bottom: 5px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 16px;

  @media screen and (max-width: 968px) {
    display: none;
  }
}

.reset-search-button {
  display: flex;
  align-items: center;
  border: 2px solid $blue-opacity-65;;
  color: $blue;
  white-space: nowrap;
  height: 25px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-transform: none;
  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: 968px) {
    display: none;
  }
}

.search_page_desktop_pagination_wrap {
  margin-left: 100px;
  margin-right: 100px;

  @media screen and (max-width: 1000px) {
    margin-left: 50px;
    margin-right: 50px;
  }
}