@import '~styles/variables';

.trail_review_recommend {
  width: 40px;
  height: 40px;
}

.top-review-icons {
    display: flex;
    width: auto;
    justify-content: flex-end;

    @media screen and (max-width: 700px) {
      justify-content: flex-start;
    }

.top-review-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 12px 0px 12px;
    white-space: nowrap;
    position: relative;
    color: $orange;

    @media screen and (max-width: 700px) {
      margin: 0px 6px 0px 6px;
    }

    span {
      display: flex;
      background-color: $orange-opacity-05;
      border-radius: 50%;
      padding: 8px;
    }

    .hover-text {
      visibility: hidden;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      width: auto;
      background-color: $dark-gray;
      color: $white;
      text-align: center;
      padding: 5px 5px;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      top: 36px;
      right: 36px;
    }

    &:hover .hover-text {
      visibility: visible;
    }
  }
}