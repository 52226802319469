@import '~styles/variables';

.trail_review_wrapper {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 0px 6px 0px 6px;

    hr {
        opacity: .3;
        width: 100%;
        height: 1px;
    }
}

.trail_review_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
}

.trail_review_footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    @media screen and (max-width: 350px) {
        flex-direction: column;
    }
}

.trail_review_user_about {
    display: flex;
    height: 100%;
    color: $review-user-about-gray;
}

.trail_review_user_text_info {
    margin-left: 10px;

    .trail_review_user_name {
    font-size: 18px;
    line-height: 21px;
    }

    .trail_review_create_date {
        font-size: 12px;
        line-height: 14px;
    }
}

.trail-review-headline {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: $review-text-gray;
    margin-bottom: 10px;
}

.trail-review-body {
    font-size: 15px;
    line-height: 18px;
    color: $review-text-gray;
    margin-bottom: 25px;
    white-space: pre-wrap;
}

.trail_review_helped_users_text {
    font-size: 13px;
    line-height: 15px;
    color: $review-helped-users-gray;
}

.trail_review_helped_users {
    display: flex;
    align-items: center;
}

.trail_review_helpful_button_active {
    background-color: $review-helpful-button-gray;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
    margin: 0px 12px 0px 12px;
    padding: 2px 12px 2px 12px;
}

.trail_review_helpful_button {
    background-color: white;
    color: $light-gray;
    border: 2px solid $review-helpful-button-gray;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
    margin: 0px 12px 0px 12px;
    padding: 0px 10px 0px 10px;
}

.trail_review_delete_button {
    background-color: white;
    color: $review-delete-button-red;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-transform: none;
    margin: 0px 12px 0px 12px;
    padding: 2px 12px 2px 12px;
}
