@import "~styles/variables";

.blog_home_preview {
    display: flex;
    flex-direction: column;
    margin: 20px;
    height: 360px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 23px;
    text-align: start;
    box-sizing: border-box;
}

.blog_home_preview_background_image {
    background-size: cover;
    background-position: center;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
}

.blog_home_preview_background_image_window {
    height: 193px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    width: 300px;
}

.blog_home_preview_top {
    height: 193px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    padding-bottom: 20px;
    display: flex;
    color: white;
    background-color: $medium-blue-50-opacity;
    width: 300px;
    box-sizing: border-box;
}

.blog_home_preview_title {
    align-self: flex-end;
    font-family: Prohibition, sans-serif;
}

.blog_home_preview_bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 168px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    background-color: white;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

    width: 300px;
    padding: 20px 20px 5px 20px;
    box-sizing: border-box;
}

.blog_home_preview_description {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    color: gray;
    font-weight: lighter;
    font-size: 14px;
}

.blog_home_preview_read_button {
    display: flex;
    background-color: $blue;
    color: white;
    font-size: 16px;
    font-weight: 300;
    padding: 6px 12px;
    height: fit-content;
    width: 100px;
    align-content: center;
    justify-content: center;
}

.blog_home_preview_bottom_footer {
    display: flex;
    justify-content: space-between;
    height: 51px;
}

.blog_home_preview_trail_name {
    color: $lime;
    font-size: 15px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    white-space: nowrap;

    a {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;
        height: 16px;
        width: 100%;
        justify-content: flex-end;
    }

    img {
        margin-right: 7px;
        height: 16px;
        width: fit-content !important;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.blog_home_preview_title {
    font-size: 30px;
    margin: 0 30px;
}

.blog_home_preview_footer_right {
    display: flex;
    flex-direction: column;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    letter-spacing: normal;
    color: $review-helpful-button-gray;
    font-weight: lighter;
    font-size: 14px;
    justify-content: flex-end;
}

.blog_home_preview_date_and_author {
    display: flex;
    margin-top: 5px;
    font-size: 12px;
}

.blog_home_preview_author {
    color: $orange;
    font-weight: bolder;
}

.blog_home_preview_city_state {
    color: $lime;
    font-weight: lighter;
    font-size: 14px;
    display: flex;
    align-self: flex-end;
}

.blog_home_preview_inline_hasthags {
    color: $lighter-blue;
}
