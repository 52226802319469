@import "~styles/variables";

.navigationBarItem {
  height: 75%;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-transform: uppercase;
  font-size: 17px;
  color: $dark-gray;
  padding: 10px 0px;
}

.activeItem {
  color: $lime;
  border-bottom: 3px solid $lime;
}
