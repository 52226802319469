@import '~styles/variables';

.city-name {
  background-color: transparent;
  color: $lime;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-left: 20px;
  margin: 10px 0 10px 20px;
}

.city-description {
  color: $blue;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: normal;
  margin-left: 30px;
}

.cityList__line {
    margin: 0px;
    opacity: 0.4;
  }

.preview-list {
  height: 560px;
}

.trailList-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.state-link {
  margin: 20px 10px 0 5%;
  font-size: 18px;
  color:$blue;
  cursor: pointer;

  &:hover {
      text-decoration: underline;
    }
}

.cityList-mapList {
  overflow-y: auto;
  overflow-x: hidden;
  height: 542px;
}