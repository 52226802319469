@import '~styles/variables';

.blog_page_wrapper {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.blog_content_wrapper {
    padding: 0px 70px;
    max-width: 1200px;
    background-color: $page-background-gray;
    align-self: center;
    min-width: 900px;

    @media screen and (max-width: 1040px) {
        min-width: 100%;
        padding: 0;
    }

    .blog_page_blog_content {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        color: $medium-gray;
        font-weight: 100;
        max-width: 860px;
        font-size: 18px;
        display: block;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.blog_page_image_container {
    width: 1040px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-self: center;

    @media screen and (max-width: 1041px) {
        width:100%;
    }
}

.blog_page_hero_image {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color:rgba(0, 0, 0, 0.2);
    background-image: url('../../../../images/heroframe4.svg');
    background-size:cover;
    background-position: center;

    @media screen and (max-width: 1041px) {
        width:100vw;
    }
}

.blog_page_title {
    color: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 45px;
    font-weight: 100;
    margin: none;
}

.blog_page_subtitle {
    color: white;
    font-size: 20px;
}

.blog_page_date {
    color: $orange;
}

.blog_page_blog_header {
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;

    @media screen and (max-width: 580px) {
        flex-direction: column;
    }

    &__left {
        display: flex;
        flex-direction: column;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        letter-spacing: normal;
        color: $review-helpful-button-gray;
        font-weight: lighter;
        white-space:normal;
        font-size: 14px;
        justify-content: flex-end;
    }
}

.blog_page_blog_trail_name {
    color: $lime;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.blog_page_blog_city_state {
    color: $lime;
    font-weight: lighter;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.blog_page_blog_date_and_author {
    display: flex;
    margin-top: 5px;
}

.blog_page_blog_author {
    color: $orange;
    font-weight: bolder;
}

.blog_page_hashtag {
    background-color: $light-blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
    font-size: 16px;
    cursor: default;
    text-transform: none;
    padding: 5px 15px;
    margin: 5px;
}

.blog_facebook_share_button {
  display: flex;
  background-color: $facebook-blue;
  align-items: center;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  text-transform: none;
  padding: 4px 12px;
  padding-right: 20px;
  margin-left: 10%;
  font-size: 17px;
  border-radius: 23px;
  box-sizing: border-box;
  border: none;
  outline: none;
  cursor: pointer;
}

.blog_twitter_share_button {
  display: flex;
  background-color: $twitter-blue;
  align-items: center;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  text-transform: none;
  padding: 4px 12px;
  padding-right: 20px;
  margin-left: 10%;
  font-size: 17px;
  border-radius: 23px;
  box-sizing: border-box;
  border: none;
  outline: none;
  cursor: pointer;
}

.blog_share_buttons {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 530px) {
        justify-content: center;
    }
}