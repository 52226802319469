.social-section {
  display: flex;
  justify-content: space-around;
  margin-right: 150px;

  @media screen and (max-width: 500px) {
    align-items: center;
    justify-content: space-around;
  }

  .contact {
    margin: 15px 0px 20px 0px;

    a {
    color: white;
  }
}

.social-link {
  padding: 10px 0px;
}

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    .state-info {
      margin-left: 50px;

      @media screen and (max-width: 500px) {
        margin-left: 0px;
      }

    .social-text {
      color: white;
      font-size: 17px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.06px;
    }

    .fa-icon-social-rect {
      height: 24px;
      width: 24px;
      border-radius: 7px;
      background-color: white;
      color: black;
      display: flex;
      margin-right: 8px;
      justify-content: center;

      .fa-icon-social {
        width: 15px;
        height: 15px;
        margin: auto;
      }
    }
  }
  }
}
