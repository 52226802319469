@import '~styles/variables';

.more_blogs_wrapper {
    width: 100%;
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;

    h3 {
        color: $orange
    }
}

.more_blog_links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;

    @media screen and (max-width: 810px) {
        flex-direction: column;
        align-items: center;
    }
}