@import '~styles/variables';

.navbar__user-icon {
    align-self: center;
    position: relative;

    .navbar__icon {
        background-color: lightgray;
        border-radius: 100px;
        padding: 10px 13px;
        font-size: 22px;
        height: 25px;
        width: 18px;
        display: flex;
        align-items: center;
        border: solid;
        border-color: transparent;
    }

    .navbar__icon__active {
      border-color: $lime;
    }

    .navbar__photo {
        border-radius: 100px;
        margin-left: 16px;
        height: 50px;
        width: 50px;
        border: solid;
        color: transparent;
    }

    .navbar__photo__active {
        color: $lime;
    }
}

.user_menu_drop {
    max-height: 0;
    overflow: hidden;
    transition: .5s;
    position: absolute;
    top: 50px;
    right: 0px;
    text-align: left;
    background: rgba(#fff, .9);
    margin-left: 1vw;
    margin-right: 1vw;
    padding: 0;
    border-radius: 6px;
  
    &.user_menu_drop_active {
      border: solid;
      border-color: $lime;
      max-height: 100px;
      padding: 4px 0 12px;
      z-index: 2;
    }
  
    li {
      list-style: none;
      padding: 8px 36px;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;

      color: #808080;
  
      &:hover {
        color: $blue;
      }
    }
  }