@import '~styles/variables';

.auth-modal-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: fixed;
  box-sizing: border-box;
  align-items: center;
  padding: 21px;
  background-color: rgba(0,0,0, 0.9); 
  z-index: 1;

  .auth_logo {
    margin-bottom: 10px;
  }
  
  .close-modal-button {
    color: #f7f9fa;
    align-self: flex-end;
    cursor: pointer;
  }

  .modal-border {
    display: flex;
    flex-direction: column;
    background-color: #f7f9fa;
    overflow-y: auto;
    align-items: center;
    position: fixed;
    padding: 21px;
    min-width: 223px;

    @media screen and (max-width: 500px) {
      position: relative;
      align-self: center;
      justify-self: center;
      width: 223px;
    }

    @media screen and (min-width: 500px) {
      left: 25%;
      right: 25%;
      top: 10%;
    }

    .auth__logotype {
      margin-bottom: 10px;
    }

    .heading-text {
      margin-bottom: 20px;

      p {
        font-family: Prohibition, sans-serif;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.84px;
        text-align: center;
        color: #061822;
        padding: 0;
        margin: 1px 0 0;
      }
    }

    .input-heading-text {
      margin: 19px 0;

      p {
        font-family: OpenSans, sans-serif;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.54;
        letter-spacing: normal;
        text-align: center;
        color: #87949d;
        padding: 0;
        margin: 0;
      }
    }

    .text-input {
      border-radius: 4px;
      border: solid 1px #cdd2d5;
      background-color: #ffffff;
      width: 50%;
      min-width: 173px;
      padding: 11px 15px;
      box-sizing: border-box;

      &[name="email"] {
        margin-bottom: 10px;
      }

      &[name="password"] {
        margin-bottom: 7px;
      }

      &::placeholder {
        font-family: OpenSans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.57;
        letter-spacing: normal;
        color: #aebbc4;
        font-weight: 300;
      }
    }

    .google-button, .fb-button {
      font-family: OpenSans, sans-serif;
      font-size: 10px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: unset;
      color: #061822;
      position: relative;
      margin: unset;
      width: 220px;
      flex-direction: row;
      background-color: $lime-opacity-05;

      .button-text {
        text-align: center;
        margin: 10px 20px;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      .google-icon, .strava-icon, .fb-icon {
        position: absolute;
        top: 0;
        left: 18px;
      }

      .fb-icon {
        color: #3c5a99;
        height: 100%;
        width: 24px;
      }

      .google-icon {
        height: 100%;
        width: 24px;
      }

      .strava-icon {
        height: 70%;
        width: 24px;
        top: 17%;
      }
    }

    .btn-wrap {
      height: 40px;
      width: 100%;
      margin: 2px 0;
      text-align: center;
      flex-direction: row;
    }

    .remember-me-checkbox-input-wrap {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      justify-content: center;
      margin: 5px 0;
    }

    .action-button:not(.btn-wrap) {
      background-color: $lime-opacity-05;
      padding: 10px 85px;
      color: #061822;
      font-family: OpenSans, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: lighter;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: lowercase;
      margin: unset;

      &:first-letter {
          text-transform: uppercase;
        }
    }

    .action-button.btn-wrap {
      margin-top: 13px;
      font-weight: 1;
    }

    .bottom-text {
      font-family: OpenSans, sans-serif;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: center;
      color: #87949d;
      margin: 20px 0;

      .bottom-text-link {
        color: $blue;
        font-weight: 600;
      }

      .link-wrap {
        display: inline-block;
        width: auto;
        height: auto;
        margin: unset;

        a {
          font-family: OpenSans, sans-serif;
          font-size: 13px;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.54;
          letter-spacing: normal;
          text-align: center;
          color: $blue;
          font-weight: 600;
        }
      }
    }
  } 
}