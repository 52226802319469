@mixin grid-justify-between-columns($columnCount, $columnItemClass) {
  display: grid;
  grid-template-columns: max-content repeat($columnCount - 1, 1fr max-content);

  .#{$columnItemClass} {
    $j: 1;
    @for $i from 1 through $columnCount {
      &:nth-child(#{$columnCount}n + #{$i}) {
        grid-column: #{$j} / #{$j + 1};
      }

      $j: $j + 2;
    }
  }
}