@import './variables';

body,
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: Prohibition, sans-serif;
  vertical-align: baseline;
  background: $white;
  letter-spacing: 1.06px;
  height: 100vh;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@font-face {
  font-family: 'Prohibition';
  src: url('../fonts/Prohibition-Regular.otf');
}
.no-scroll{
  overflow:hidden;
}
