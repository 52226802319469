@import "~styles/variables";

.search-bar__bar {
  display: flex;
  align-items: center;
  position: relative;

  .search-bar__icon {
    position: absolute;
  }

  input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    letter-spacing: .5px;
    width: 110px;
    padding: 4px 8px 4px 24px;

    &::placeholder {
      font-style: italic
    }
    ::-webkit-input-placeholder {
    font-style: italic;
    }
    :-moz-placeholder {
    font-style: italic;  
    }
    ::-moz-placeholder {
      font-style: italic;  
    }
    :-ms-input-placeholder {  
      font-style: italic; 
    }

    &:focus {
      outline: 0;
    }
  }
}

.search-bar__bar-white {
  .search-bar__icon {
    color: #fff;
  }

  input {
    color: #fff;
    border-bottom-color: #fff;
  }
}

.search-bar__bar-black {
  .search-bar__icon {
    color: $medium-gray;
  }

  input {
    color: #000;
    border-bottom-color: $medium-gray;

  }

  ::placeholder {
    color: $review-helpful-button-gray;
  }
}

.search-bar__results {
  position: absolute;
  top: 42px;
  padding: 12px 18px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-width: 150px;
  color: #000;
  z-index: 2;
}

.search-bar__results-header {
  margin-bottom: 12px;
}

.search-bar__results-list {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: .5px;
  padding: 0px 18px;

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 12px;
      padding: 8px;

      &:hover {
        background: rgba(#ccc, .5);
        cursor: pointer;
      }
    }
  }
}

.search-bar__results-trail-name {
  font-weight: 500;
  font-size: 16px;
}

.search-bar__results-advanced {
  text-align: center;
  padding: 8px;
  color: $lime;

  &:hover {
    background: rgba(#ccc, .5);
    cursor: pointer;
  }
}

.search-form-container {
  margin-left: auto;
}
