@import '~styles/variables';

.info-header {
  color: $lime;
  font-weight: normal;
  margin-bottom: .5%;
}

.tag-group {
  display: inline;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: normal;
  color: gray;

  .tags-details-header {
    font-weight: bold;
  }

  .details-tag-name {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

}

.first-row {
  display: flex;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: normal;
  color: gray;
  flex-direction: row;

  .climb-header {
      font-weight: bold;
      margin: 3% 0;
      display: flex;

      .climb-text {
        font-weight: normal;
        margin-left: 5px;
      }
    }

  .info-group {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .group-header {
      font-weight: bold;
      margin: 3% 0;
      display: flex;
      flex-direction: column;
      width: 100%;

      .group-list {
        display: flex;
        flex-direction: column;
        font-weight: normal;
        margin: 2% 0;

        span {
          margin: 1% 0;
        }
      }
    }
  }

  .elevation-group {
    display: flex;
    width: 100%;
  }
}

.trail-elevation-image {
  padding-left: 2rem;
  max-height: 40px;
  max-width: 110px;
}
