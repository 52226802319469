@import '~styles/variables';

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width:  $size !important;
        height: $size !important;
        -webkit-overflow-scrolling: auto;
        -webkit-appearance: none;
      }
    
      &::-webkit-scrollbar-thumb {
        background: $foreground-color !important;
      }
    
      &::-webkit-scrollbar-track {
        background: $background-color !important;
      }
    
      // For Internet Explorer
      & {
        scrollbar-face-color: $foreground-color !important;
        scrollbar-track-color: $background-color !important;
      }
}

.profile_info_header {
    background-color: transparent;
    color: $lime;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
    }

.profile_user_field {
    color: $blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-bottom: 1px solid #E8E8E8;
    height: 25px;
    flex-direction: row;
    min-width: 150px;
}

.profile_user_value {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    min-height: 19px;
    border-bottom: 1px solid #E8E8E8;
    max-width: 350px;
    padding: 10px 0;
    overflow-x: auto;

    &__photo_url {
        margin: 100px 0;
        max-width: 350px;
        padding: 15px 0;
        border-bottom: 1px solid #E8E8E8;
        overflow-x: auto;
        white-space: nowrap;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

        @include scrollbars(1px, $blue, $light-gray);

    }
}

.profile_password_user_field {
    padding-top: 19px;
    color: $blue;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    height: 25px;
    flex-direction: row;
}

.profile_password_user_value {
    padding-top: 1px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    min-height: 19px;
}

.profile_user_cancel_button {
    color: #808080;
    border: 1px solid #727D84;
    box-sizing: border-box;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-transform: none;
}

.profile_user_save_button {
    color: white;
    background-color: $lime;
    box-sizing: border-box;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    font-family: 'Alegreya Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-transform: none;
}

.profile_update_pass_button {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: none;
    color: #808080;
    border: 1px solid #727D84;
    box-sizing: border-box;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    background-color: white;
}