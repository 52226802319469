@import '~styles/variables';

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size !important;
      height: $size !important;
      -webkit-overflow-scrolling: auto;
      -webkit-appearance: none;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color !important;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color !important;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color !important;
      scrollbar-track-color: $background-color !important;
    }
}

.blog_preview_background_image {
    background-size: cover;
    background-position: center;
}

.blog_preview_background_image_window {
    height: 200px;
    width: 500px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @media screen and (max-width: 900px) {
        width: 300px;
    }
}

.blog_preview_top {
    height: 180px;
    width: 500px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom: 20px;
    display: flex;
    color: white;
    background-color:$medium-blue-50-opacity;

    @media screen and (max-width: 900px) {
        width: 300px;
    }
}

.blog_preview_title {
    align-self: flex-end;
    font-family: Prohibition, sans-serif;
}

.blog_preview_bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 460px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
    padding: 20px;
    @include scrollbars(1px, $blue, $light-gray);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    @media screen and (max-width: 900px) {
        width: 260px;
        padding: 20px 20px 5px 20px;
    }
}

.blog_preview {
    display: flex;
    flex-direction: column;
    margin: 20px;

    @media screen and (max-width: 330px) {
        margin: 10px;
    }
}

.blog_preview_description {
    padding: 2% 0%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: normal;
    color: gray;
    font-weight: lighter;
    white-space:normal;
    font-size: 18px;
    margin: 0 30px;

    @media screen and (max-width: 900px) {
        margin: 0 0 9px 0;
        font-size: 16px;
    }
}

.blog_preview_read_button {
    display: flex;
    background-color: $blue;
    color: white;
    font-size: 14px;
    font-weight: 300;
    text-transform: none;
    padding: 12px 25px;
    height: -moz-fit-content;
    height: fit-content;
    font-family: Prohibition, sans-serif;

    @media screen and (max-width: 900px) {
        align-self: center;
        padding: 6px 12px;
        width: 50%;
        justify-content: center;
        margin-top: 5px;
        letter-spacing: 1px;
    }
}

.blog_preview_bottom_footer {
    display: flex;
    justify-content: space-between;
    margin: 0 30px;

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        margin: 0px;
    }
}

.blog_preview_trail_name {
    color: $lime;
    font-weight: normal;
    font-size: 16px;
    align-self: flex-end;
    display: flex;
    align-items: center;

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        margin-right: 7px;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    @media screen and (max-width: 900px) {
        align-self: flex-start;
    }
}

.blog_preview_title {
    font-size: 30px;
    margin: 0 30px;
}

.blog_preview_footer_right {
    display: flex;
    flex-direction: column;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: normal;
    color: $review-helpful-button-gray;
    font-weight: lighter;
    white-space:normal;
    font-size: 14px;
    justify-content: flex-end;
}

.blog_preview_date_and_author {
    display: flex;
    margin-top: 5px;
}

.blog_preview_author {
    color: $orange;
    font-weight: bolder;
}

.blog_preview_city_state {
    color: $lime;
    font-weight: lighter;
    font-size: 14px;
    display: flex;
    align-self: flex-end;

    @media screen and (max-width: 900px) {
        align-self: flex-start;
    }
}

.blog_preview_inline_hasthags {
    color: $lighter-blue;
}
