@import '~styles/variables';

@keyframes slide-right-to-center {
   0% {right: -200px;}

  100% {right: 0px;}

}

@keyframes slide-center-to-right {
   0% {right: 0px;}

  100% {right: -200px;}
  
}

.filter_hidden {
    display: none;
}

.filter_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
  position: fixed;
  box-sizing: border-box;
  background-color: rgba(0,0,0, 0.4); 
  z-index: 1;
 
  .filter_border {
    position: fixed;
    right: -200%;

    &_active {
        animation-name: slide-right-to-center;
        animation-duration: .5s;
        position: fixed;
        right: 0%;
        display: flex;
        flex-direction: column;
        background-color: #f7f9fa;
        height: 100vh;
        width: 60%;
        opacity: 0.94;
    }

    &_closed {
        animation-name: slide-center-to-right;
        animation-duration: .5s;
        position: fixed;
        right: -200%;
        display: flex;
        flex-direction: column;
        background-color: #f7f9fa;
        height: 100vh;
        width: 60%;
    }
    }

    .filter_header {
        display: flex;
        justify-content: space-between;
        max-height: 100px;
        font-size: 32px;
        color: $lime;
        background-color: $blue;
        width: 100%;
        
        .filter_closeBtn {
            color: white;
            background-color: #8BA0AD;
            border: none;
            border-radius: 100%;
            height: 32px;
            margin: 6px;
            font-size: 20px;
        }

        .filter_clearBtn {
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 10px;
            border-radius: 0px;
            height: 50%;
            align-self: center;
            margin-right: 8px;
        }
    }
}

    .filter_borderBody {
        overflow-y: auto;
    }

    .filter_itemBody {
        display: flex;
        flex-direction: column;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        color: $blue;

        .filter_arrowIcon_down {
            transform: rotate(360deg);
            transition-duration: .5s;
        }

        .filter_arrowIcon_up{
            transform: rotate(180deg);
            transition-duration: .5s;
        }
        
        .filter_menuOption {
            display: flex;
            justify-content: space-between;
            padding: 5px 10px;
            width: 90%;
            color: $blue;
            font-weight: bold;
        }

        .filter__sortOptions {
            max-height: 0;
            overflow: hidden;
            transition: .5s;
            text-align: left;
            background: #B1AFAF, .9;
            padding: 0;
            border-radius: 6px;

        &.filter__sortOptions_active {
            max-height: 350px;
            z-index: 1;
        }

        li {
            list-style: none;
            padding: 8px 16px;
            width: 250px;
            font-weight: normal;
        }
    }
    }