.rounded-button {
  text-transform: uppercase;
  font-size: 17px;
  border-radius: 23px;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: Prohibition, sans-serif;
  cursor: pointer;
}
