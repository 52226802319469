@import '~styles/variables';

.trail-comm-wrapper {
    border-width: 1px 0px 0px 0px;
    border-color: $lime;
    border-style: solid;
    display: flex;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
}

.trail-comm-header {
    display: flex;
    align-items: center;

    @media screen and (max-width: 745px) {
        flex-direction: column;
    }
}

.trail-comm-title {
    color: $lime;
    font-family: Prohibition, sans-serif;
    margin-right: 15px;

    @media screen and (max-width: 600px) {
        margin-top: 0px;
    }
}

.trail-comm-button-wrap {
    display: flex;

    @media screen and (max-width: 745px) {
        margin-bottom: 15px;
    }

    @media screen and (max-width: 455px) {
        flex-direction: column;
        align-items: center;
    }
}

.trail-comm-active-button {
    background: #F4FBDC;
    border: 1px solid #83B027;
    box-sizing: border-box;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $blue;
    margin: 0px 10px 0px 10px;
    width: 200px;
    padding: 5px 0px 5px 0px;

    @media screen and (max-width: 455px) {
        margin-bottom: 20px;
    }
}

.trail-comm-inactive-button {
    background: #FFFFFF;
    border: 1px dashed #727D84;
    box-sizing: border-box;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #808080;
    margin: 0px 10px 0px 10px;
    width: 200px;
    padding: 5px 0px 5px 0px;

    @media screen and (max-width: 455px) {
        margin-bottom: 20px;
    }
}

.trail-comm-displays {
    margin-top: 15px;
    width: 100%;
}

.trail-comm-form-access {
    margin-top: 87.88px;
    width: 20vw;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-top: 10px;
        width: auto;
    }
}

.trail-comm-form-button {
    display: flex;
    background-color: $lime;
    color: white;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    text-transform: none;
    padding: 12px;
    height: fit-content;
    margin: 0% 5%;
    margin-bottom: 20px;

    @media screen and (max-width: 600px) {
        margin: 0;
    }
}
