@import '~styles/variables';

.pdf-content {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.pdf-object {
  width: 50%;
  min-height: 500px;
  align-self: center;
  min-width: 320px;
}

.sheet-content {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.sheet-object {
  width: 50%;
  min-height: 500px;
  align-self: center;
  min-width: 320px;
}
