@import '~styles/variables';

.city-block {
  background-color: transparent;
  color: $blue;
  padding: 0px 24px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: normal;
  margin: 0px;

  &:hover {
    background: $light-gray;
    cursor: pointer;
  }

  .cityList__line {
    margin: 0px;
    opacity: 0.4;
  }
}

.cities_state-name {
  background-color: transparent;
  color: $lime;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-left: 10px;
}

.citiesList__mapList {
  overflow-y: auto;
  overflow-x: hidden;
  height: 508px;
}

.cityList__city-details {
  display: flex;
  justify-content: space-between;
}

.cityList__trail-number {
  align-self: flex-end;

}

.cityList__sort-bar {
  background-color: $blue;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  color: white;

  .cityList__sort-bar span {
    padding: 20px;
  }

}
.city-header {
  background-color: transparent;
  letter-spacing: 1px;
  margin-left: 10px;
  color: $blue;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 400;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.map-details {
    overflow: hidden;
    border: 0;
    min-height: 300px;
    max-height: 660px;
    width: 100%;
  }

.cityList-header {
  display: flex;
}

.filter-button {
  display: flex;
  align-items: center;
  align-self: center;
  color: $blue;
  white-space: nowrap;
  height: 24px;
  margin-left: auto;
  margin-right: 20px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 14px;
  border: 2px solid $blue-opacity-65;;
  text-transform: none;
  font-weight: bold;
}