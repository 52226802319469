@import '~styles/variables';

.preview-trail-detail {
  padding: 18px;

  @media screen and (max-width: 968px) {
    padding-left: 1px;
  }

  &:hover {
    background: $light-gray;
    cursor: pointer;

    .preview-icons .preview-icon span:nth-child(2n+1) {
      background-color: white;
    }
  }

  &:focus {
    background: $light-gray;
    outline-width: 0;

    .preview-icons .preview-icon span:nth-child(2n+1) {
      background-color: white;
    }
  }
}

.page-details {
  width: 100%;
  display: flex;

  @media screen and (max-width: 968px) {
    display: block;
  }

  .text {
    max-width: 200px;
  }

  .preview__text-details {
    height: 660px;
    
    @media screen and (min-width: 968px) {
    min-width: 400px;
    }
    
    .trail-title {
      color: $blue;
      font-weight: 500;
      margin: 0;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      letter-spacing: normal;
      width: 100%;
    }
  }

  .preview-text-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .preview-description {
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;

    .preview-description-text {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: normal;
    color: gray;
    font-weight: normal;
    width: 500px;
    display: flex;
    white-space: normal; 
    overflow: hidden;
    text-overflow: ellipsis;
    }
  }

  .preview-hero-image {
    background-size: cover;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 0px 12px 6px 0px;
    height: 90px;
    width: 200px;
  }
}

.recommend {
  color: $orange;
  background-color: $orange-opacity-05;
  font-size: 14px;
  cursor: initial;
  letter-spacing: .5px;
  margin: 0% 5%;
}
