$blue: #254c65;
$lime: #b0dd01;
$light-gray: #cdd2d5;
$white: #ffffff;
$dark-gray: #3d4f5a;
$dark-gray-opacity-20: #333d4f5a;
$ad-background: #F7F9FA;
$filter-trail-button-background: #E9E9E9;
$orange: #ff9736;
$orange-opacity-05: #FFEBD8;
$site-red: #ed6464;
$site-red-opacity-20: #ed646433;
$lime-opacity-05: rgba(176, 221, 1, 0.5);
$page-background-gray: rgba(245, 248, 249, 1);
$subtitle-gray: #A9A9A9;
$review-text-gray: #87949D;
$review-user-about-gray: #3C4F5B;
$review-helped-users-gray: #98ADBC;
$review-helpful-button-gray: #C1C1BD;
$review-delete-button-red: #D76161;
$point-of-interest-blue: #207FB1;
$light-blue: #2973A2;
$lighter-blue: #5e96b9;
$medium-gray: rgb(117, 117, 119);
$blue-opacity-65: rgba(37, 76, 101, 0.65);

$medium-blue-50-opacity: rgba(6, 24, 34, 0.5);

$facebook-blue: #3b5998;
$twitter-blue: #00acee;