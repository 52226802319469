@import '~styles/variables';

.page-details {
  width: 100%;

  @media screen and (max-width: 968px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .text-details {
    width: 60%;

    @media screen and (max-width: 968px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
    
    .trail-name {
      color: $lime;
      font-weight: normal;
      width: 100%;
      margin-top: 17px;
    }
  }

  .trail__trail-icons {
    padding: 0% 5%;
  }

  .text-header {
    padding: 0% 5% 0% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description-header {
    padding: 0% 5%;
    color: $lime;
    font-weight: normal;
  }

  .description {
    padding: 0% 5%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: normal;
    color: gray;
    font-weight: normal;
    white-space: pre-wrap;
  }

  .trail__map-details {
    display: flex;
    border: 0;
    width: 100%;
    flex-flow: column nowrap;
    
    @media screen and (max-width: 968px) {
    display:none;
    }
  }

  .mobile_map {
    display: none;
    
    @media screen and (max-width: 968px) {
    border: 0;
    min-height: 300px;
    max-height: 900px;
    width: 100%;
    }
  }
}

.trail__link-text {
  color: $lime;
}

.trail_directions_share_buttons {
  display: flex;
  flex-direction: row;
}

.facebook_share_button {
  display: flex;
  background-color: $facebook-blue;
  align-items: center;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  text-transform: none;
  padding: 4px 12px;
  padding-right: 20px;
  margin: 0% 10%;
  font-size: 17px;
  border-radius: 23px;
  box-sizing: border-box;
  border: none;
  outline: none;
  cursor: pointer;
}

.twitter_share_button {
  display: flex;
  background-color: $twitter-blue;
  align-items: center;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  text-transform: none;
  padding: 4px 12px;
  padding-right: 20px;
  margin: 0% 10%;
  font-size: 17px;
  border-radius: 23px;
  box-sizing: border-box;
  border: none;
  outline: none;
  cursor: pointer;
}

.directions {
  display: flex;
  background-color: transparent;
  border: 1px solid $blue;
  color: $lime;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 600;
  text-transform: none;
  padding: 4px 12px;
  margin-left: 5%;
  align-items: center;
}

.trail__hero-image {
  @media screen and (min-width: 968px) {
    padding: 0 5%;
    }
}

.recommend {
  color: $orange;
  background-color: $orange-opacity-05;
  font-size: 14px;
  cursor: initial;
  letter-spacing: .5px;
  margin: 0% 5%;
}

.more-info {
  padding: 0px 27px 27px 27px;
}

.related-routes_mobile {
  display: none;
    
    @media screen and (max-width: 968px) {
    display: flex;
    }
}

.points_of_interest_mobile {
  display: none;
    
    @media screen and (max-width: 968px) {
    display: flex;
    margin-bottom: 10px;
    }
}

.city_state_section {
  font-size: 18px;
  margin-top: 5px;
  color: $blue;
  font-weight: 300;

  .city-link {
    margin: 10px 0 0 10px;
    font-size: 18px;
    color:$blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

