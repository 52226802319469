@import '~styles/variables';

.blog_comment_section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        color: $blue;

        @media screen and (max-width: 1040px) {
           padding-left: 10px;
        }
    }
}
