@import '~styles/variables';

.checkbox-wrap {
  display: flex;
  outline: none;
  margin: 5px 0px;

  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 1px #cdd2d5;
    background-color: #ffffff;
    position: relative;

    &:before {
      content: '✔';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.2s linear;
      text-align: center;
      font-size: 15px;
    }

    &.checked {
      &:before {
        opacity: 1;
      }
    }
  }

  .checkbox-text {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $blue;
    display: flex;
    margin: auto auto auto 10px;
  }
}