@import '~styles/variables';

.mobileSearch__header {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: $blue;
    display: flex;
    justify-content: space-between;
    margin: 18px;
    align-items: center;

     button {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 15px;
        color: $blue;
        border-style: solid;
        border-width: .75px;
        border-radius: 2px;
        border-color: #BFBFBF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        padding: 8px;
        margin-left: 10px;
        margin-right: 10px;

        @media screen and (max-width: 322px) {
            span {
                width: 100%;
                font-size: 11px;
            }

        .search__sortSelect {
            max-width: 200px;
        }
        }
    }

    .search__sortIcon {
        margin: 5px;
        color: #AEBBC4;
    }
    .search__trailCount {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        button {
            margin: 5px 3px 0px 5px;
        }
    }
    .search__trailCountHidden {
        display: none;
    }

    .search__sortOptions {
        max-height: 0;
        overflow: hidden;
        transition: .5s;
        position: absolute;
        top: 42px;
        text-align: left;
        background: rgba(#fff, .9);
        margin-left: 1vw;
        margin-right: 1vw;
        padding: 0;
        border-radius: 6px;

    &.search__sortOptions_active {
        max-height: 150px;
        padding: 4px 0 12px;
        z-index: 2;
    }

    li {
        list-style: none;
        padding: 8px 16px;
        color: #000;

    &:hover {
      color: $blue;
    }
  }
}
}
.mobileSearch__menuBar_active {
    justify-content: space-between;
    padding: 0px 0px 0px 10px;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    background-color: $blue;
    display: flex;

        img {
            padding-top: 10px;
        }
}

.mobileSearch__menuBar {
    padding: 5px 23px;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    background-color: $blue;
    display: flex;
    
    .filterIcon {
        height: 42px;
        width: 42px;
        justify-content: center;
        border-color: transparent;
        background-color: transparent;
    }
}

.search__trailCard {
    margin: 10px;
}